<template>
  <div id="app" class="login">
    <RouterView />
    <SiteFooter />
  </div>

  <vue3-toastify />
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";

export default {
  components: {SiteFooter},
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
