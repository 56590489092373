<template>
  <div class="flex flex-row">
    <div class="flex flex-row relative w-full">
      <input type="text" class="flex-1 bg-wuh-secondary rounded-2xl mr-3 px-4" v-model="debouncedSearchtext" placeholder="Suchen">
      <button @click="debouncedSearchtext = ''" class="absolute right-0 top-0 mt-[-2px] mr-3 " type="button">
        <img src="@/assets/icons/close.svg" class="w-10 h-10"/>
      </button>
    </div>

    <a href="#" class="">
      <img src="@/assets/icons/filter.svg" class="w-10" />
    </a>
  </div>
  <div class="flex flex-row">
    <div class="flex justify-between mt-3">
      <div class="flex w-3/5">
        <ul class="tagfilters flex w-full">
          <li v-for="(item, index) in filteroptions.tags" v-bind:key="item">
            <a href="#" @click.prevent="addfiltertag(item, index)" class="tagbubble">#{{ item }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['filteroptions'],
  data() {
    return {
      filter: {
        searchtext: '',
        searchtags: []
      },
      // filteroptions: {
      //   tags: [],
      //   dates: []
      // },
    }
  },
  computed: {
    debouncedSearchtext: {
      get() {
        return this.filter.searchtext
      },
      set(value) {
        clearTimeout(this.timer)
        // const hashtagDetected = /#\w+/g.test(value); // checking for existence of hashtag
        let timout = 500
        this.timer = setTimeout(() => {
          // this.loadimages()
          console.log('debouncedSearchText', value) //.match(/#\w+/g))
          // // if(value.indexOf('#') > 0 && value.match(/#\w+/g) > 5) {
          //   this.filter.searchtags = [...this.filter.searchtags, ...(value.match(/#\w+/g) || [])];
          //   let clearedfield = value.replace(/#\w+/g, '').trim();
          //   this.filter.searchtext = clearedfield
          // // } else {
          this.filter.searchtext = value

          this.$emit('updateFilter', this.filter);
          // // }
        }, timout)
      }
    }
  },
  methods: {

    addfiltertag(item, index) {
      console.log(index)
      this.filter.searchtext = this.filter.searchtext + ' #' + item
    },
    removetag(index) {
      // let r = this.filter.searchtags.splice(index, 1);
      this.filter.searchtags.splice(index, 1);
      this.filter.searchtags = [...this.filter.searchtags]
      console.log('Splice element ', index, this.filter.searchtags)
      // this.filter.searchtags = r
    },
  },
  watch: {
    'filter.searchtext'(value, oldval) {
      // this.inputValue = value;
      // this.debounceInput = value;
      // const hashtagDetected = /#\w+/g.test(value); // checking for existence of hashtag

      console.log('filter.searchtext change', value, oldval)

      this.$emit('updateFilter', this.filter);
      // if (!hashtagDetected) { // if no hashtag is found
      //   this.loadimages()
      // }
    },
    'filter.searchtags'(value, oldval) {
      // this.inputValue = value;
      // this.debounceInput = value;
      console.log('filter.searchtags change', value, oldval)
      // this.loadimages()
    }
  }

}
</script>

<style lang="scss" scoped>
.tagfilters li a {
  font-size: 0.6rem;
}
.tagbubble {
  @apply py-0.5 px-1.5 bg-wuh-lightgreen rounded-2xl inline-flex items-center text-xxs;
}
</style>
