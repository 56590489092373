<template>
  <div class="fixed bottom-0 left-0 w-full bg-wuh-green h-10 z-50 footerstyle">
    <div class="flex justify-between ">
      <div class="flex p-2 text-white">
        &copy; Wald und Holz NRW
      </div>
      <div class="flex ">

        <ul class="flex justify-self-end w-auto text-white">
          <li class="p-2 inline-flex border-right">
            <a href="#" @click.prevent="showHelpModal = true" class="text-white">Hilfe</a>
          </li>
          <li class="p-2 inline-flex border-right">
            <a href="#" @click.prevent="showImpressumModal = true" class="text-white">Impressum</a>
          </li>
          <li class="p-2 inline-flex ">
            <a href="#" @click.prevent="showDatenschutzModal = true" class="text-white">Datenschutz</a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <ModalView :show="showHelpModal" @close="showHelpModal = false" >
    <div class="w-[50vw]">
      Hilfe
    </div>
  </ModalView>
  <ModalView :show="showImpressumModal" @close="showImpressumModal = false" >
    <div class="w-[50vw]">
      Impressum
    </div>
  </ModalView>
  <ModalView :show="showDatenschutzModal" @close="showDatenschutzModal = false" >
    <div class="w-[50vw]">
      Datenschutz
    </div>
  </ModalView>

</template>

<script>
import ModalView from "@/components/ModalView.vue";

export default {
  components: {ModalView},
  data() {
    return {
      showHelpModal: false,
      showImpressumModal: false,
      showDatenschutzModal: false,
    }
  }

}
</script>

<style lang="scss" scoped>
.footerstyle {
  border-top: 1px solid white;
}

.border-right {
  border-right:1px solid white;
}



</style>
