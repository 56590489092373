<template>
  <div class="flex flex-col w-full mb-2">
    <textarea class="metainputfield min-h-[75px]" placeholder="Einen Kommentar schreiben" v-model="comment"></textarea>
    <span class="flex flex-col w-full text-sm text-red-600" v-if="error">{{error}}</span>
  </div>

  <div class="flex w-full items-center justify-center mb-4">
    <button class="btn-default " @click.prevent="saveComment()">
      Speichern
    </button>
  </div>

  <div class="flex flex-col w-full mb-4">

    <blockquote class="comment" v-for="(item, index) in comments" v-bind:key="item">
      <span v-if="isdeleteable(item, index)" @click.prevent="removeComment(item)" class="absolute top-0 right-0 w-5 h-5 close-comment-btn"><img src="@/assets/icons/close.svg" /></span>
      <p>{{ item.content }}</p>
      <span class="absolute bottom-[-1rem] right-0.5 text-xs">{{formatDate(item.cr_date)}} von {{item.user}}</span>


    </blockquote>

  </div>

  <ConfirmationDialog title="Eintrag wirklich löschen?"
                      :item="delitem"
                      cancelbtn="Abbrechen"
                      submitbtn="Wirklich löschen!"
                      :show="showRemoveDialog"
                      @submit="removeComment"
                      @cancel="removeComment">
    <div class="mt-5 mb-5">
      <p>Möchten sie den Eintrag wirklich löschen?</p>
    </div>
  </ConfirmationDialog>
</template>

<script>
// import axios from "axios";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import apiService from "@/services/apiService";
import {toast} from "vue3-toastify";

export default {
  name: 'FileComments',
  components: {ConfirmationDialog},
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      comments: [],
      comment: '',
      error: '',
      showRemoveDialog: false,
      delitem: null
    }
  },
  mounted() {
    console.log('FileItem', this.item.fileid)
    this.loadcomments()
  },
  computed: {
  },
  methods: {

    isdeleteable(item, index) {
      console.log('Deletable Item', item, index)
      if(this.$store.state.currentuser.user.role == 'admin') {
        console.log('Deleteable cause IS ADMIN')
        return true
      }

      if(this.comments.length -1 == index) {
        if(item.user == this.$store.state.currentuser.user.username ) {
          console.log('Deleteable Cause last item and is users item')
          return true
        }
      }

      return false
    },

    async removeComment(item, action = null) {

      if(action == null) {
        this.delitem = item
        this.showRemoveDialog = true
      } else {

        console.log('Remove Item', action, item)

        if(action === true) {
            await apiService.removeComment({
              fileid: this.item.fileid,
              commentid: item.id
            })
            .then((response) => {
              if(response.data.status == 'success') {
                this.comments = response.data.data.comments
                toast.success('Kommentar wurde erfolgreich gelöscht.')
              }
            })
            .catch((error) => {
              console.error('Es gab einen Fehler beim Senden des Post-Requests.', error);
            });
        }

        this.showRemoveDialog = false

      }
    },

    async loadcomments() {
      await apiService.getComments({fileid: this.item.fileid})
      // await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/getcomments', {fileid: this.item.fileid})
        .then((response) => {
          this.comments = response.data.data.comments
        })
        .catch((error) => {
          console.error('Es gab einen Fehler beim Senden des Post-Requests.', error);
        })
    },
    async saveComment() {

      if(this.comment != '' && this.item.fileid != '') {

        await apiService.saveComment({
          fileid: this.item.fileid,
          comment: this.comment
        })
        // await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/savecomment', {
        //   fileid: this.item.fileid,
        //   comment: this.comment
        // })
            .then((response) => {

              if (response.data.status == 'error') {
                this.error = 'Kommentar konnte nicht erstellt werden'
                return
              }

              this.comments = response.data.data.comments
              this.comment = ''
              toast.success('Kommentar wurde erfolgreich gespeichert.')
            })
            .catch(() => {
              console.log('Error')
            })

      } else {
        this.error = 'Bitte einen Kommentar verfassen'
        setTimeout(() => {
          this.error = ''
        }, 2500)
      }
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
