import axios from "axios";

const state = {
    files: false,
    currentpath: [],
    filters: []
};
const mutations = {

};
const actions = {
    fetchall({commit}) {
        console.log('Fetch All in Path', commit, state.currentpath)
    },
    async fetchfiles({commit}) {
        console.log('Fetch Files in Path', commit, state.currentpath)
    },
    async fetchfolders({commit}) {
        console.log('Fetch Folders in Path', commit, state.currentpath)
    },
    async fetchimage(_, item) {
        // console.log('Fetch Images in Path', commit, state.currentpath)
        await axios.get(process.env.VUE_APP_APP_APIURL + `/api/getfile/${item.fileid}`, {
            responseType: 'blob', // Important
        })
        .then(response => {
            item.orgimgSrc = URL.createObjectURL(new Blob([response.data]));
        })
        .catch(error => {
            console.error(error);
        })
    },
    async fetchimages(_, images, type = 'thumb') {
        // console.log('Fetch Images in Path', commit, state.currentpath)

        for (let item of images) {
            try {

                if(item.thumbnail != '') {
                    item.imgSrc = item.thumbnail
                } else {
                    const response = await axios.get(`${process.env.VUE_APP_APP_APIURL}/api/getfile/${item.fileid}/${type}`, {
                        responseType: 'blob',
                    });
                    item.imgSrc = URL.createObjectURL(new Blob([response.data]));
                }

            } catch (error) {
                console.error(error);
            }
        }
        // console.log('Fetch Images in Path END', commit, state.currentpath)
    },
    testfetch({commit}) {
        console.log('Fetch Files in Path', commit, state.currentpath)
    },
    setCurrentPath({commit}, path) {
        state.currentpath = path
        console.log('Set CurrentPath to', commit, state.currentpath)
    },
    async download({commit}, fileids = false) {

        console.log('Initiate Download',commit, fileids)

        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/downloadbyid',
            {
                fileids: fileids
            },
            {
                responseType: 'blob',
            }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let contentType = response.headers['content-type'];
            let fileExtension = contentType.split('/')[1];
            // const fileName = `${fileId}.${fileExtension}`;
            const fileName = `Files_.${fileExtension}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    },
    filesmoveImage(_commit, item) {
        console.log('moveImage ', item, item.title)
    },
    async filesremoveImage(_commit, item) {
        console.log('removeImage ', item, item.title)
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/removeimage',
            {
                fileids: item.fileid
            }).then((response) => {
                return response.data.status == 'success'
        })
    },
    filesmoveFolder(_commit, item) {
        console.log('moveFolder ', item, item.title)

    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
