import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "vue-select";
import MasonryWall from "@yeger/vue-masonry-wall";
import InfiniteLoading from 'vue-infinite-scroll';
import VueDatePicker from '@vuepic/vue-datepicker';
import Vue3Toastify, {toast} from 'vue3-toastify';

import '@vuepic/vue-datepicker/dist/main.css';

import filters from './filters/filters';

import 'vue3-toastify/dist/index.css';
import "./assets/tailwind.css";
import "vue-select/dist/vue-select.css";
import axios from "axios";
import PerfectScrollbar from "vue3-perfect-scrollbar";

import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import {plugin as VueTippy} from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            localStorage.removeItem('user-token')
            axios.defaults.headers.common['Authorization'] = '';
            console.log('Unauthorized - Redirecting...');
            router.push({'name':'login'})
            return
        }
        return Promise.reject(error);
    }
);

window.ResizeObserver = undefined;
window.addEventListener('error', (event) => {
    if (event.message.includes('ResizeObserver loop limit exceeded')) {
        event.stopPropagation();
        event.preventDefault();
    }
});

console.log('VUE_APP_APP_API_URL', process.env.VUE_APP_APP_APIURL)

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('user-token')}`;

const app = createApp(App)
app.use(VueTippy, {
    directive: 'tippy',
    component: 'tippy'
})
app.mixin(filters)
app.use(Vue3Toastify, {
        autoClose: 5000,
        position: toast.POSITION.BOTTOM_RIGHT,
    })
app.use(store)
app.use(router)
app.use(MasonryWall)
app.use(InfiniteLoading)
app.use(PerfectScrollbar)
app.component('v-select', vSelect)
app.component('VueDatePicker', VueDatePicker)
app.component('ImageCropper', Cropper)
app.mount('#app')
