<template>
  <a href="#" class="mr-0 ml-1" @click.prevent="showModal = true" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
    <img src="@/assets/icons/add-image.svg" class="w-10" />
  </a>

  <ModalView :show="showModal" @close="showModal = false" >
<!--    <h1>Bildupload in {{currentpath.length}}</h1>-->
<!--    <h1>Bildupload in {{ currentpath[currentpath.length - 1].path}}</h1>-->
<!--    <h1>Bildupload in {{ currentpath }}</h1>-->

      <div v-if="iswriteable">
        <div id="drop-zone"
             @dragover.prevent="dragOver"
             @drop.prevent="drop"
             :class="{ hover: isDrag }">

          <input type="file"
                 id="fileElem"
                 multiple
                 accept="image/*"
                 @change="handleFiles($event.target.files)"
                 class="hidden">

          <p>Datei(n) hier herziehen und los lassen</p>
          <label class="button" for="fileElem">Oder hier klicken und auswählen</label>
        </div>
        <div v-for="(progress, index) in uploadProgress" :key="index" class="w-full">
          <div class="relative pt-1 w-full">
            <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-wuh-lightgreen h-[1.5rem]">
              <div id="progress" :style="{ width: progress + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-wuh-green h-[1.5rem]"></div>
            </div>
            <div id="percentage" class="text-gray-700 absolute w-full text-center top-0 pt-1">
              {{ progress }}%
            </div>
          </div>
        </div>
<!--        <div v-for="(uploadedfile, index) in uploaded" :key="index">-->
<!--          File {{ index + 1 }}: {{ uploadedfile }}%-->
<!--        </div>-->
      </div>

  </ModalView>

</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";
import ModalView from "@/components/ModalView.vue";
import emitter from "@/addons/event-bus";

export default {
  name: 'ImageUpload',
  components: {ModalView},
  props: {
    currentpath: {
      type: Array
    },

  },
  data() {
    return {
      files: [],
      showModal: false,
      isDrag: false,
      uploadPercentage: 0,
      iswriteable: true,
      uploadProgress:[],
      uploaded:[]
    }
  },
  computed: {
    computedProp() {
      if(this.currentpath.length >= 1) {
        return this.currentpath
      }
      return [{path:'/'}]
    }
  },
  mounted() {
    console.log('Currentuser', this.$store.state.currentuser.user)
  },
  methods: {
    ...mapActions([]),
    dragOver(e) {
      this.isDrag = true;
      e.preventDefault();
    },
    drop(e) {
      this.isDrag = false;
      let files = e.dataTransfer.files;

      console.log('Files Drop', files)
      if (files.length > 0) {
        this.handleFiles(files)
      }
    },
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        console.log('Handle Files', files[i].name)
        if (/\.(jpe?g|png|gif)$/i.test(files[i].name)) {
          this.files.push(files[i])
          this.uploadFile(files[i]);
        }
      }
    },
    async uploadFile(file) {
      let _this = this;
      console.log('File', file, this.currentpath);
      if(!this.files) {
        console.error('No file selected');
        return;
      }

      this.uploadProgress = {};

      // Convert FileList to Array
      const files = Array.from(this.files);
      const index = files.indexOf(file)

      // for (const [index, file] of files.entries()) {
      const formData = new FormData();
      formData.append('file', file);
      if(_this.currentpath.length >= 1) {
        const lastPath = _this.currentpath[_this.currentpath.length - 1].path;
        formData.append('path', lastPath);
        // formData.append('path', _this.currentpath[_this.currentpath.legnth-1].path)
      } else {
        console.log('Currentuser', _this.$store.state.currentuser.user)
        formData.append('path', _this.$store.state.currentuser.user.defaultuploadfolder)
        // formData.append('path', '/')
      }
      console.log('Formdata', formData)

      try {
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          // Provide onUploadProgress callback function that updates the uploadProgress
          onUploadProgress: progressEvent => {
            this.uploadProgress[index] = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
            );
            // Check if upload progress is 100%
            if (this.uploadProgress[index] === 100) {
              // Remove uploaded file from the list
              if (index > -1) {
                this.uploadProgress[index] = 'Daten werden aufbereitet.'
                this.uploaded.push(this.files[index]);
                this.files = this.files.splice(index, 1);
              }
            }
          }
        }).then((data) => {
          this.uploadProgress[index] = 'Daten erfolgreich aufbereitet.'
          console.log('Response', data);
          if(data.data.fileid) {
            emitter.emit('filesuploaded', data.data.data.image)
            console.log('Emmiter filesuploaded called!')
            this.showModal = false
          }

          // alert('Upload finished!');
        }).catch(error => {
          console.log('error', error)
        })

        this.$store.commit('globalstore/SET_LOADING', false)

      } catch (error) {
        console.error('Error', error);
        this.$store.commit('globalstore/SET_LOADING', false)
      }


      // Here you can send the file with an AJAX request to your server for upload
    },
  },
  watch: {
    currentpath: function() {
      console.log('Currentpath', this.currentpath)
      if(this.currentpath.length -1 == -1) {
        this.iswriteable = true
      } else {
        let currentpath = this.currentpath[this.currentpath.length -1]
        let pathpermission = currentpath.permissions.indexOf('CK')
        console.log('Pathpermissions', pathpermission > 0)
        this.iswriteable = pathpermission > 0

      }
    }
  }

}
</script>

<style lang="scss" scoped>

.hidden {
  display: none;
}

#drop-zone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease-in-out;
}

#drop-zone.hover {
  background-color: #f0f0f0;
}

.button {
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
</style>
