<template>
      <div v-if="$store.state.globalstore.loading" class="fixed inset-0 flex items-center justify-center z-[5000]" style="background-color: rgba(0,0,0,0.5);">
        <div class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-wuh-green"></div>
      </div>
      <div class="flex justify-between min-h-screen font-Roboto bg-wuh-primary" v-if="$store.state.auth.token">
        <SiteNavigation />
        <perfect-scrollbar class="flex-grow pt-10 px-10 bg-white pb-10 relatived max-h-screen" ref="mainscroll">
            <RouterView />
        </perfect-scrollbar>
        <ImageDetails @close-image-details="unsetcurrentphoto"/>
      </div>
    <SiteFooter />

</template>

<script>
import SiteNavigation from "@/components/SiteNavigation.vue";
import ImageDetails from "@/components/ImageDetails.vue";
import {mapActions} from "vuex";
import SiteFooter from "@/components/SiteFooter.vue";
// import store from "@/store";

export default {
  components: {SiteFooter, ImageDetails, SiteNavigation},
  data() {
    return {
      showImageDetails: false,
      isloading: false
    }
  },
  computed: {
    // singleImagePhoto() {
    //   // console.log('singleImagePhoto', this.$store.state.singleimage.photo)
    //   // return this.$store.state.singleimage.photo;
    // }
  },
  created() {
    this.$store.commit('globalstore/SET_LOADING', true)
    // if(store.state.auth.token) {
    this.getuser(this.$store.state.auth.token);
      // this.getuser('user');
    // }
    console.log('DefaultLayout created')
    this.$store.commit('globalstore/SET_LOADING', false)
  },
  methods: {
    ...mapActions(['getuser']),
    unsetcurrentphoto() {
      if (!this.$store) {
        console.error("Vuex store is not defined.");
        return;
      }
      try {
        this.$store.commit('singleimage/SET_SINGLE_IMAGE', false);
        console.log('Closed success')
      } catch (error) {
        console.error("Error committing Vuex mutation:", error);
      }
    }
  },
  watch: {
    $route(to, from) {
      if(to.fullPath != from.fullPath) {
        this.$refs.mainscroll.$el.scrollTop = 0;
      }
    }
    // singleImagePhoto: function(newVal){
    //   console.log('NewValue', newVal)
    //   // if(this.$store.state.singleimage.photo) {
    //   //   this.showImageDetails = true
    //   // } else {
    //   //   this.showImageDetails = false
    //   // }
    //
    // }
  }
}
</script>

<style lang="scss">
    a {
      @apply text-wuh-green mr-2
    }
</style>
